import React from "react";
import { bannermacbookimg } from "../Imagesjs/Images";
import adminpanelwebm from "../assets/images/adminpanel.webm";
import adminpanel from "../assets/images/adminpanel.mp4";
import "./DashboardSlider.css";

function DashboardSlider() {
  return (
    <>
      <div className="img-frame position-relative macbook">
        <i>
          <img
            src={bannermacbookimg}
            alt="macbook"
            className="img-fluid w-100"
          />
        </i>
        <div className="videobg">
          <video muted autoPlay loop playsInline>
            <source src={adminpanelwebm} data-wf-ignore="true" />
            <source src={adminpanel} data-wf-ignore="true" />
          </video>
        </div>
      </div>
    </>
  );
}

export default DashboardSlider;
