import React from "react";
import { Link } from "react-router-dom";
import CertifiedSupplierIcons from "../CertifiedSupplierIcons/CertifiedSupplierIcons";
import {
  email,
  phoneicon,
  insta,
  twitter,
  facebook,
  Linkdin,
  youtube,
  tictok,
  certifiedicon2,
  certifiedicon,
  logo,
  logolight,
} from "../Imagesjs/Images";
import Playstoreicons from "../Playstoreicons/Playstoreicons";
import "./Footer.css";
function Footer(props) {
  const d = new Date();
  let year = d.getFullYear();
  let calcScrollValue = () => {
    let scrollProgress = document.getElementById("progress");
    let pos = document.documentElement.scrollTop;
    let calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      scrollProgress.style.display = "grid";
    } else {
      scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () =>
      window.scrollTo({ top: 0, behavior: "smooth" })
    );
    scrollProgress.style.background = `conic-gradient(var(--primary) ${scrollValue}%, var(--grey) ${scrollValue}%)`;
  };

  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;

  const CertifieIcons = [
    {
      icon1: certifiedicon2,
    },
    {
      icon1: certifiedicon,
    },
  ];
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-6 footer-widget-common mb-4 mb-lg-0">
              <h4 className="wow fadeInUp" data-wow-delay="0.2s">
                Information
              </h4>
              <ul className="footer-links">
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/investors">Investors</Link>
                </li>
                <li>
                  <Link to="/termsandcondition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-6 footer-widget-common mb-4 mb-lg-0">
              <h4 className="wow fadeInUp" data-wow-delay="0.2s">
                Help
              </h4>
              <ul className="footer-links">
                <li>
                  <Link to="/leaderShip-team">Leadership</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 footer-widget-common reach_us mb-4 mb-lg-0">
              <h4 className="wow fadeInUp" data-wow-delay="0.2s">
                Contact us
              </h4>
              <p>
                <a href="mailto:info@gasitup.com">
                  <i>
                    <img src={email} alt="email" height={22} />
                  </i>
                  info@gasitup.com
                </a>
              </p>
              <p>
                <a href="tell:1-888-300-3999">
                  <i>
                    <img src={phoneicon} alt="phone" height={22} />
                  </i>
                  1-888-300-3999
                </a>
              </p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 footer-widget-common mb-4 mb-lg-0">
              <h4 className="wow fadeInUp" data-wow-delay="0.2s">
                Download Our App
              </h4>
              <div className="fc">
                <Playstoreicons />
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-widget-common reach_us mb-4 mb-lg-0">
              <h4 className="wow fadeInUp" data-wow-delay="0.2s">
                Social Links
              </h4>
              <ul className="footer_social_links">
                <li className="wow fadeInUp" data-wow-delay="0.1s">
                  <span className="linkedin">
                    <a
                      href="https://www.linkedin.com/company/gas-it-up-inc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={Linkdin} alt="Linkdin" />
                      </i>
                    </a>
                  </span>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.2s">
                  <span className="instagram">
                    <a
                      href="https://www.instagram.com/gasitup_/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={insta} alt="insta" />
                      </i>
                    </a>
                  </span>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.3s">
                  <span className="facebook">
                    <a
                      href="https://www.facebook.com/GasItUpFuel"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={facebook} alt="facebook" />
                      </i>
                    </a>
                  </span>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.4s">
                  <span className="twiter">
                    <a
                      href="https://www.twitter.com/gasitup_/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={twitter} alt="twitter" />
                      </i>
                    </a>
                  </span>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.5s">
                  <span className="youtube">
                    <a
                      href="https://www.youtube.com/@gasitup2475/featured"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={youtube} alt="youtube" />
                      </i>
                    </a>
                  </span>
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.6s">
                  <span className="tictok">
                    <a
                      href="http://www.tiktok.com/@gasitupfuel"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="">
                        <img src={tictok} alt="tictok" />
                      </i>
                    </a>
                  </span>
                </li>
              </ul>
              <div className="d-flex align-items-center justify-content-start certified-icon mt-4 footercertifiedicons">
                <CertifiedSupplierIcons CertifieIcons={CertifieIcons} />
              </div>
            </div>
          </div>
        </div>

        <div className="foote-copyrights wow fadeInUp">
          <hr />
          <div className="container">
            <div className="row align-items-center justify-content-between pb-3">
              <div className="col-md-6 col-12 text-md-start text-center">
                <h5
                  className="d-flex align-items-center justify-content-md-start justify-content-center mb-0 wow fadeInDown"
                  data-wow-delay="0.2s"
                >
                  <Link to="/">
                    <i className="d-flex align-items-center">
                      <img
                        src={props.theme === "dark" ? logo : logolight}
                        alt="sitelogo"
                        height={60}
                        className=""
                      />
                    </i>
                  </Link>
                </h5>
              </div>
              <div className="col-md-6 col-12 text-md-end text-center py-3 py-md-1">
                <p className="m-0">
                  &copy; 2017-{year} Gas It Up. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
