import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  checkwhite,
  download,
  downloadicon,
  relax,
  relaxicon,
  schedule,
  scheduleicon,
} from "../Imagesjs/Images";
import IphoneSlider from "../IphoneSlider/IphoneSlider";
import Playstoreicons from "../Playstoreicons/Playstoreicons";
import "./DownloadSchedule.css";

function DownloadSchedule(props) {
  const iphoneimage = [
    { iosimage: download },
    { iosimage: schedule },
    { iosimage: relax },
  ];
  const [activeStep, setActiveStep] = useState(0);

  const downloadList = [
    "Download the app to your smartphone and register to create your profile...",
    "Schedule when and where you want your fuel delivered, using the map...",
    "Relax and our fuel technicians will leave your car full and ready to go...",
  ];
  const downloadimg = [downloadicon, scheduleicon, relaxicon];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prev) => (prev >= downloadList.length - 1 ? 0 : prev + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [downloadList.length]);

  return (
    <>
      <section className="common-section-all downlaod-schedule">
        <div className="container">
          <div className="row align-items-center finger-tips mx-0 py-5">
            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 ps-lg-5">
              <div className="heading-style text-md-start text-center listing-list ps-lg-5">
                <h5
                  className="text-md-start text-center wow fadeInUp py-3"
                  data-wow-delay="0.1s"
                >
                  <span
                    id="Download"
                    className={activeStep === 0 ? "text-black" : ""}
                  >
                    {" "}
                    Download.
                  </span>{" "}
                  <span
                    id="Schedule"
                    className={activeStep === 1 ? "text-black" : ""}
                  >
                    {" "}
                    Schedule.
                  </span>{" "}
                  <span
                    id="Relax"
                    className={activeStep === 2 ? "text-black" : ""}
                  >
                    {" "}
                    Relax.
                  </span>
                </h5>
                {props.downLoadPros.IsRewrite ? (
                  <h4 className="wow fadeInUp pe-lg-5" data-wow-delay="0.2s">
                    <div className="pe-lg-5">
                      <span className="d-flex text-start px-md-0 px-3">
                        <i className="me-lg-4 me-2 icon">
                          <img
                            src={downloadimg[activeStep]}
                            alt="downloadimg"
                            width="80px"
                          />
                        </i>
                        {downloadList[activeStep]}
                      </span>
                    </div>
                  </h4>
                ) : (
                  <ul className="downloadschedulelising">
                    {props.downLoadPros.downloadlist.map(
                      (downloaddatalist, i) => (
                        <li
                          className="wow fadeInLeft"
                          data-wow-delay={`0.` + i + `s`}
                          key={i}
                        >
                          <i className="me-4">
                            <img src={checkwhite} alt="checkwhite" />
                          </i>
                          {downloaddatalist.list}
                        </li>
                      )
                    )}
                  </ul>
                )}
                <Link
                  to="/our-process"
                  className="btn btn--primary clickcustomform wow animate fadeInUp px-5 border bg-purple mt-4"
                  data-wow-delay="0.3s"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  <span>Learn More</span>
                </Link>
                <Playstoreicons />
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 fuelscreen fueling-fingertipsslider">
              <figure className="mx-auto">
                <IphoneSlider
                  iphoneimage={iphoneimage}
                  activeStep={activeStep}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DownloadSchedule;
