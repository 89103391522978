import React from "react";
import "./Services.css";
function Services(props) {
  return (
    <>
      <section className="common-section-all pb-0 services">
        <div className="container">
          {props.IsHead === true ? (
            <div className="col-md-12">
              <div className="heading-style text-center wow fadeInUp">
                {props.IsHeadOurprocess === false ? (
                  <h5>
                    We Can <span className="text-orange">Fuel You!</span> Gas It
                    Up <span className="text-orange">Services</span>
                  </h5>
                ) : (
                  <h5>
                    You’re in <span className="text-orange">Good Hands</span>
                  </h5>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {props.IsSubHeadcore === true ? (
            <div className="col-md-12">
              <div className="heading-style text-center wow fadeInUp">
                <h5>
                  Core<span className="text-orange"> Values</span>
                </h5>
              </div>
            </div>
          ) : (
            ""
          )}
          <ul className="row pt-5 mt-30">
            {props.IsSubHead === true ? (
              <li className="col-lg-4 col-md-6 col-12 pb-5 subhead_title">
                <div
                  className="card bg-none wow fadeInDown"
                  data-wow-delay="0.2s"
                >
                  <div className="card-body text-left">
                    <h3 className="card-title pt-1">
                      <span className="text-white" data-text="Core">
                        <span>Core</span>
                      </span>
                      <span className="text-orange">Values</span>{" "}
                    </h3>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
            {props.serviceblock.map((serviceitem, i) => (
              <li
                className={`${
                  window.location.pathname ===
                    "/business-fleets-trucking-and-construction-companies" || "/edm-pa" ||
                  window.location.pathname === "/our-process"
                    ? "col-lg-4"
                    : "col-lg-3 core-center"
                } col-md-6 col-12 pb-5`}
                key={i}
              >
                <div
                  className="card bg-none wow fadeInDown"
                  data-wow-delay={"0." + i + "s"}
                >
                  <div className="box-shadow text-center head-icon">
                    {props.IsIconHead === true ? (
                      <div className="headicon">
                        <i className="">
                          <img src={serviceitem.icon} alt="serviceitem icon" />
                        </i>
                        <h4>{serviceitem.title}</h4>
                      </div>
                    ) : (
                      <i>
                        <img src={serviceitem.icon} alt="serviceitem icon" />
                      </i>
                    )}
                  </div>
                  <div className="card-body text-left">
                    <h3 className="card-title pt-1">{serviceitem.ItemTitle}</h3>
                    <p className="card-text text-sm">
                      {serviceitem.ItemDescription}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}

export default Services;
