import React from "react";
import {
  enviromentimpactbg,
  enviromentimpactbgwhite,
  enviromentimpactcontactbanner,
  enviromentimpactthumb,
  giu,
  linehorn10,
  linehorn6,
  linehorn7,
  linehorn8,
  linehorn9,
} from "../../Imagesjs/Images";
import Calculator from "../../Calculator/Calculator";
import "./EnvironmentImpact.css";
import Partners from "../../Partners/Partners";
import Contact from "../../Contactus/Contact";
function EnvironmentImpact(props) {
  const type = 12;
  const contactbanner = enviromentimpactcontactbanner;
  return (
    <>
      <section className="inner-banner section-banner enviromentimpact">
        <div className="background-image">
          <img
            src={
              props.theme === "dark"
                ? enviromentimpactbg
                : enviromentimpactbgwhite
            }
            alt="enviroment impact"
          />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-md-6 col-12">
              <div className="home-banner-texter text-md-start text-center">
                <h1>
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.2s"
                  >
                    THE GAS IT UP{" "}
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    EFFECT
                  </span>
                </h1>
                <p className="text-captialized">
                  Gas it Up can create something special that redefines
                  <br /> the experiences of fleet management and efficiency.
                  <br /> Our business intelligence, technology, and world-class
                  <br /> platforms can create the ultimate fleet experience and
                  a<br /> greener, brighter future.
                </p>
              </div>
            </div>
            <div className="col-md-6 wow zoomIn">
              <figure>
                <img
                  src={enviromentimpactthumb}
                  alt="THE GAS IT UP EFFECT"
                  className="img-fluid w-100"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="common-section-all pb-0">
        <div className="container">
          <div className="tracking-details">
            <div className="row align-items-center gx-0">
              <div className="col-lg-6 col-12">
                <figure>
                  <img
                    src={giu}
                    alt="Every Tank Fillup Takes About 1.4 Pounds of"
                    className="img-fluid"
                    width="100%"
                  />
                </figure>
              </div>
              <div className="col-lg-6 col-12">
                <div className="heading-style text-start px-lg-5 mb-0">
                  <h5
                    className="text-start text-capitalize wow fadeInUp mb-0"
                    data-wow-delay="0.1s"
                  >
                    Every Tank Fillup Takes <br />
                    About 1.4 Pounds of <br />
                    <span className="text-orange">Carbon Out of The Air!</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Calculator theme={props.theme} />
      <section className="common-section-all intro-sistercompny">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center mb-0">
              <div className="intro-horn">
                <i className="horn-left d-flex flex-column">
                  <img
                    src={linehorn6}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.2s"
                  />
                  <img
                    src={linehorn7}
                    alt="line"
                    className="wow fadeInUp me-4"
                    data-wow-delay="0.4s"
                  />
                  <img
                    src={linehorn8}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.6s"
                  />
                </i>
                <h5 className="wow fadeInUp" data-wow-delay=".1s">
                  <span className="text-white">
                    {" "}
                    CO<sub>2</sub> Rewards Program
                  </span>
                  <span className="text-orange"> Coming Soon!</span>
                </h5>

                <i
                  className="horn-right d-flex flex-column"
                  data-wow-delay=".2s"
                >
                  <img
                    src={linehorn9}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.2s"
                  />
                  <img
                    src={linehorn7}
                    alt="line"
                    className="wow fadeInUp ms-4"
                    data-wow-delay="0.4s"
                  />
                  <img
                    src={linehorn10}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.6s"
                  />
                </i>
              </div>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Across the country, bills and regulations are being passed to
                guide the mobile-fueling industry. Especially in today’s <br />
                enviornment, cities, corporations, and venues are looking for
                ways to keep things going while also being safe.
              </p>
              <p className="wow fadeInUp mb-0" data-wow-delay=".6s">
                Our active partnerships in other markets throughout the country
                are just the beginning! Don’t get caught in the <br />
                past, become a trailblazer that is changing the future of
                fueling!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By <span className="text-orange">The Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange"> Gas It Up</span> professional
                  that <br /> can get your
                  <span className="text-orange"> business </span>
                  <span>set up for </span>{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default EnvironmentImpact;
