import React from "react";
import "./CertifiedSupplierIcons.css";

function CertifiedSupplierIcons(props) {
  return (
    <>
      <ul className="d-flex align-items-center justify-content-lg-start justify-content-center certified-icon">
        {props.CertifieIcons.map((certifuelicon, i) => (
          <li
            key={i}
            className="text-center wow fadeInUp"
            data-wow-delay={".0" + i + "s"}
          >
            <i>
              <img
                src={certifuelicon.icon1}
                alt="certifuel icon"
                className="img-fluid"
              />
            </i>
            <span className="d-block" style={{ marginTop: "10px" }}>
              {certifuelicon.IsHeading ? certifuelicon.title : ""}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
}

export default CertifiedSupplierIcons;
