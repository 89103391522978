import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect } from "react";
import Schema from "../../schema/Courseshmeaedm";
import Schema1 from "../../schema/Formsshema";
import useGooglePlacesAutocomplete from '../../directives/directive';

const ValidatedLoginForm = (props) => {
  const [formattedAddress, setFormattedAddress] = useState({
    formatted_address: '',
    locality: '',
    admin_area_l1: '',
    street_number: '',
    route: '',
    country: '',
    postal_code: '',
    name: '',
    lat: '',
    lng: '',
    formatted_address_second: ''
  });
  const [data, setData] = useState([]);
  const [citydata, setcitydata] = useState([]);

  const [county,setcounty] = useState([])
  const handler = (event) => {
    if (event.target.value !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=3&search=${event.target.value}`
        )
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        })
        .catch((err) => { });
    } else {
      setData([]);
    }
  };

  function getdata1(ele) {
    setFieldValue("zipCode", ele[0]);
    setData([]);
  }

  const handlePlaceSelect = (formattedAddress) => {
    setFormattedAddress(formattedAddress);
    setFieldValue("zipCode", formattedAddress.postal_code);
    if(formattedAddress){
    axios
    .get(
      `https://devapi-new.gasitup.com/common/getCountyUsingZipCode?zipcode=${formattedAddress.postal_code.toString()}`
    )
    .then((res) => {
      if (res?.data) {
        console.log('res',res.data)
        const countyName = res.data.data;
          setFieldValue("county", countyName);
        // setFieldValue("county",res.data);
      }
    })
    .catch((err) => { });
  }
    console.log('dd',formattedAddress);

    // Do something with the formatted address
  };

  function convertToMilliseconds(hours, minutes) {
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
  }

  const inputRef = useGooglePlacesAutocomplete(handlePlaceSelect);
  const handlercity = (event) => {
    if (event.target.value !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=3&&citySearch=${event.target.value}`
        )
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setcitydata(res.data.data);
          } else {
            setcitydata([]);
          }
        })
        .catch((err) => { });
    } else {
      setcitydata([]);
    }
  };
  const formikProps = useFormik({
    initialValues: {
      // firstName: "",
      // lastName: "",
      email: "",
      zipCode: "",
      descriptionQuote: "",
      // type: props.type ? props.type : 1,
      businessName: "",
      businessAddress: "",
      frequency: "",
      deliveryWindowStartTime: "",
      deliveryWindowEndTime: "",
      employeeSize: "",
      fleetSize: "",
      tankType : "",
      // tankType: "",
      phoneNumber: "",
      // fuelrequired: "",
      lat: '',
      long: '',
      gallons: '',
      numberOfLocation: '',
      fuelType: '',
      county: ''
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      console.log("formattedAddress",formattedAddress);
      console.log('values are ',values);
      let payload;

      if (!props.showBusinessFields) {
        console.log('iffff',)
        payload = {
          email: values.email,
          // firstName: values.firstName,
          // lastName: values.lastName,
          city: values.city,
          zipCode: values.zipCode,
          descriptionQuote: values.descriptionQuote,
          type: props.type,
          phoneNumber: values.phoneNumber,
          businessAddress: formattedAddress.formatted_address,
          // formatted_address,
          
        };
      } else {
        console.log('elseee',)
        // Calculate campaign start date (1st January of the current year) in milliseconds
        const campaignStartDate = new Date(new Date().getFullYear(), 0, 1).getTime();
       // Calculate campaign end date (31st December of the current year) in milliseconds
        const campaignEndDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59).getTime();
        const employeeSizeNumber = parseInt(values.employeeSize, 10);
        const fleetSizeNumber = parseInt(values.fleetSize, 10);
        const gallonsNumber = parseInt(values.gallons, 10);
        const numberOfLocationNumber = parseInt(values.numberOfLocation, 10);
        
        
         
        payload = {
          ...values,
          lat: formattedAddress.lat.toString(),
          long: formattedAddress.lng.toString(),
          businessAddress: formattedAddress.formatted_address,
          state: formattedAddress.admin_area_l1,
          city: formattedAddress.locality,
          zipCode: formattedAddress.postal_code,
          address1: formattedAddress.name,
          address2: formattedAddress.route,
          campaignStartDate,
          campaignEndDate,
          county: values.county,
          country:formattedAddress.country,
          employeeSize: employeeSizeNumber,
          fleetSize: fleetSizeNumber,
          gallons: gallonsNumber,
          numberOfLocation: numberOfLocationNumber
        };
        // payload = values;
        // delete values.firstName;
        // delete values.lastName;
      }
      axios
      .post('https://devapi-new.gasitup.com/campaign/createCampaign', payload)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Thank you!!",
            text: "Your interest has been submitted to us. Our team will get back to you soon!!",
            showConfirmButton: false,
            timer: 4000,
          });
          action.resetForm();
          // document.getElementById("firstName").value = "";
          // document.getElementById("lastName").value = "";
          document.getElementById("city").value = "";

          if (!props.showBusinessFields) {
            setFieldValue("businessName", "businessName");
            setFieldValue("businessAddress", "businessAddress");
            setFieldValue("frequency", "frequency");
            setFieldValue("deliveryWindowStartTime", "deliveryWindowStartTime");
            setFieldValue("deliveryWindowEndTime", "deliveryWindowEndTime");
            setFieldValue("fleetSize", "fleetSize");
            setFieldValue("numberOfLocation", "numberOfLocation");
            setFieldValue("gallons", "gallons");
            setFieldValue("employeeSize", "employeeSize");
            setFieldValue("tankType", "tankType");
            setFieldValue("fuelType", "fuelType");
          } else {
            // setFieldValue("firstName", "firstName");
            // setFieldValue("lastName", "lastName");
            setFieldValue("city", "city");
          }
        })
        .catch((err) => { });
    },
    validationSchema: props.showBusinessFields ? Schema : Schema1,
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  function getData2(ele) {
    formikProps.setFieldValue("city", ele);
    setcitydata([]);
  }

  useEffect(() => {
    if (!props.showBusinessFields) {
      setFieldValue("businessName", "businessName");
      setFieldValue("businessAddress", "bussinessaddress");
        setFieldValue("frequency", "frequency");
        setFieldValue("deliveryWindowStartTime", "deliveryWindowStartTime");
        setFieldValue("deliveryWindowEndTime", "deliveryWindowEndTime");
      setFieldValue("employeeSize", "employeeSize");
      setFieldValue("fleetSize", "fleetSize");
      setFieldValue("gallons", "gallons");
      setFieldValue("numberOfLocation", "numberOfLocation");
      setFieldValue("tankType", "tankType");
      setFieldValue("fuelType", "fuelType");
       
    } else {
      // setFieldValue("firstName", "firstName");
      // setFieldValue("lastName", "lastName");
      setFieldValue("city", "city");
    }
  }, [props.showBusinessFields, setFieldValue]);

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="row">
          {props.showBusinessFields ? (
            ""
          ) : (
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter First Name"
                className="form-control"
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
              />
              <p className="text-danger mb-0">
                {errors.firstName && touched.firstName && (
                  <span className="input-feedback">{errors.firstName}</span>
                )}{" "}
              </p>
            </div>
          )}
          {props.showBusinessFields ? null : (
            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
                id="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
              />
              <p className="text-danger mb-0">
                {errors.lastName && touched.lastName && (
                  <span className="input-feedback">{errors.lastName}</span>
                )}{" "}
              </p>
            </div>
          )}
          {props.showBusinessFields ? (
            <div className="col-md-12 form-group">
              <label>Business Name </label>
              <input
                type="text"
                placeholder="Enter Business Name"
                className="form-control"
                autoComplete="off"
                id="businessName"
                name="businessName"
                value={values.businessName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
                {errors.businessName && touched.businessName && (
                  <span className="input-feedback">{errors.businessName}</span>
                )}{" "}
              </p>
            </div>
          ) : null}
          <div className="col-md-12 form-group">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter Email Address"
              className="form-control"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.email && touched.email && (
                <span className="input-feedback">{errors.email}</span>
              )}
            </p>
          </div>
          <div className="col-md-12 form-group">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              className="form-control"
              maxLength="10"
              id="phoneNumber"
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.phoneNumber && touched.phoneNumber && (
                <span className="input-feedback">{errors.phoneNumber}</span>
              )}
            </p>
          </div>
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Business Address </label>
              <input
                type="text"
                placeholder="Enter Business Address"
                className="form-control"
                autoComplete="off"
                id="businessAddress"
                name="businessAddress"
                ref={inputRef}
              />
              {/* <p className="text-danger mb-0">
                {errors.businessAddress && touched.businessAddress && (
                  <span className="input-feedback">
                    {errors.businessAddress}
                  </span>
                )}{" "}
              </p> */}
            </div>
          ) : null}

          <div
            className={`${props.showBusinessFields ? "col-md-6" : "col-md-6"
              } form-group position-relative`}
          >
            <label>Zip Code</label>
            <input
              type="number"
              placeholder="Enter Zip Code"
              className="form-control"
              id="zipCode"
              name="zipCode"
              value={values.zipCode}
              onChange={(event) => {
                setFieldValue("zipCode", event.target.value);
              }} //
              onBlur={handleBlur}
              onKeyUp={(e) => handler(e)}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.zipCode && touched.zipCode && (
                <span className="input-feedback">{errors.zipCode}</span>
              )}
            </p>
            {/* {data.length !== 0 && (
              <ul className="zipcode-listing">
                {data.length !== 0 &&
                  data.data.map((ele, i) => {
                    return (
                      <li key={i} onClick={() => getdata1(ele?.zipCode)}>
                        {ele?.zipCode[0]}
                      </li>
                    );
                  })}
              </ul>
            )} */}
          </div>
        </div>
        <div className="row">
          {props.showBusinessFields ? (
            ""
          ) : (
            <div className="col-md-6 form-group position-relative">
              <label>City (optional)</label>
              <input
                type="text"
                placeholder="Enter City (optional)"
                className="form-control"
                id="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyUp={(e) => handlercity(e)}
                autoComplete="off"
              />
              {citydata.length !== 0 && (
                <ul className="zipcode-listing">
                  {citydata.length !== 0 &&
                    citydata.data.map((ele, i) => {
                      return (
                        <li key={i} onClick={() => getData2(ele?.city)}>
                          {ele?.city}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}

          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Employee Size</label>
              <select
                value={values.employeeSize}
                className="form-select"
                onChange={(e) => setFieldValue("employeeSize", e.target.value)}
              >
                <option>Select Employee Size</option>
                <option value="50">10-50</option>
                <option value="100">50-100</option>
                <option value="200">100-200</option>
                <option value="200">200-500</option>
                <option value="200">500-1000</option>
              </select>
              <p className="text-danger mb-0">
                {" "}
                {errors.employeeSize && touched.employeeSize && (
                  <span className="input-feedback">{errors.employeeSize}</span>
                )}
              </p>
              {/* <input
                type="text"
                placeholder="Enter Employee Size"
                className="form-control"
                autoComplete="off"
                id="employeeSize"
                name="employeeSize"
                value={values.employeeSize}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
                {" "}
                {errors.employeeSize && touched.employeeSize && (
                  <span className="input-feedback">{errors.employeeSize}</span>
                )}
              </p> */}
            </div>

          ) : null}

          {props.showBusinessFields ? (


            <div className="col-md-6 form-group">
              <label>Fleet Size</label>
              <input
                type="text"
                placeholder="Enter Fleet Size"
                className="form-control"
                autoComplete="off"
                id="fleetSize"
                name="fleetSize"
                value={values.fleetSize}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
                {" "}
                {errors.fleetSize && touched.fleetSize && (
                  <span className="input-feedback">{errors.fleetSize}</span>
                )}
              </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (


            <div className="col-md-6 form-group">
              <label>Fuel Type</label>
              <select
                value={values.fuelType}
                className="form-select"
                onChange={(e) => setFieldValue("fuelType", e.target.value)}
              >
                <option value="" className="">
                  Select Fuel Type{" "}
                </option>
                <option value="Gasoline">
                  Gasoline
                </option>
                <option value="Red/Clear">
                  {/* Diesel Red + DEF - Gasoline  */}
                  Diesel Clear /Red
                  {/* Red/Clear */}
                </option>
                <option value="Others">
                  Others
                </option>
              </select>
              <p className="text-danger mb-0">
                {" "}
                {errors.fuelType  && touched.fuelType  && (
                  <span className="input-feedback">{errors.fuelType }</span>
                )}
              </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Fuel Required (Weekly)</label>
              <input
                type="text"
                placeholder="Enter Fuel"
                className="form-control"
                autoComplete="off"
                id="gallons"
                name="gallons"
              value={values.gallons}
              onChange={handleChange}
              onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
              {" "}
              {errors.gallons && touched.gallons && (
                <span className="input-feedback">{errors.gallons}</span>
              )}
            </p>
            </div>
          ) : null}

          {props.showBusinessFields ? (


            <div className="col-md-6 form-group">
              <label>Frequency</label>
              <select
                value={values.frequency}
                className="form-select"
              onChange={(e) => setFieldValue("frequency", e.target.value)}
              >
                <option>Select Frequency</option>
                <option value="daily">Daily</option>
                <option value="alternate">Alternate Day</option>
                <option value="weekly">Weekly</option>
              </select>
              <p className="text-danger mb-0">
                {errors.frequency && touched.frequency && (
              <span className="input-feedback">
                {errors.frequency}
              </span>
            )}{" "}
              </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (
           <div className="col-md-6 form-group">
           <label>Delivery Window</label>
           <select
             value={values.deliveryWindow}
             className="form-select"
             onChange={(e) => {
               const selectedWindow = e.target.value;
               let startTime = 0;
               let endTime = 0;
               
               switch (selectedWindow) {
                 case "00:00 AM to 04:00 AM":
                   startTime = convertToMilliseconds(0, 0); // 12:00 AM
                   endTime = convertToMilliseconds(4, 0); // 4:00 AM
                   break;
                 case "04:00 AM to 08:00 AM":
                   startTime = convertToMilliseconds(4, 0); // 4:00 AM
                   endTime = convertToMilliseconds(8, 0); // 8:00 AM
                   break;
                 case "08:00 AM to 12:00 PM":
                   startTime = convertToMilliseconds(8, 0); // 8:00 AM
                   endTime = convertToMilliseconds(12, 0); // 12:00 PM
                   break;
                 case "12:00 PM to 04:00 PM":
                   startTime = convertToMilliseconds(12, 0); // 12:00 PM
                   endTime = convertToMilliseconds(16, 0); // 4:00 PM
                   break;
                 case "04:00 PM to 08:00 PM":
                   startTime = convertToMilliseconds(16, 0); // 4:00 PM
                   endTime = convertToMilliseconds(20, 0); // 8:00 PM
                   break;
                 case "08:00 PM to 00:00 AM":
                   startTime = convertToMilliseconds(20, 0); // 8:00 PM
                   endTime = convertToMilliseconds(24, 0); // 12:00 AM
                   break;
                 default:
                   startTime = 0;
                   endTime = 0;
               }
         
               // Call setFieldValue to update the state with the selected values
               setFieldValue("deliveryWindowStartTime", startTime);
               setFieldValue("deliveryWindowEndTime", endTime);
             }}
           >
             <option>Select Window</option>
             <option value="00:00 AM to 04:00 AM">00:00 AM to 04:00 AM</option>
             <option value="04:00 AM to 08:00 AM">04:00 AM to 08:00 AM</option>
             <option value="08:00 AM to 12:00 PM">08:00 AM to 12:00 PM</option>
             <option value="12:00 PM to 04:00 PM">12:00 PM to 04:00 PM</option>
             <option value="04:00 PM to 08:00 PM">04:00 PM to 08:00 PM</option>
             <option value="08:00 PM to 00:00 AM">08:00 PM to 00:00 AM</option>
           </select>
           {/* <p className="text-danger mb-0">
             {errors.deliveryWindow && touched.deliveryWindow && (
               <span className="input-feedback">
                 {errors.deliveryWindow}
               </span>
             )}{" "}
           </p> */}
         </div>
         
          ) : null}


          {props.showBusinessFields ? (


            <div className="col-md-6 form-group">
              <label>Tank Type</label>
              <select
                value={values.tankType }
                className="form-select"
              onChange={(e) => setFieldValue("tankType", e.target.value)}
              >
                <option value="" className="">
                  Select Tank Type{" "}
                </option>
                <option value="Gasoline">
                  Over Ground
                </option>
                <option value="Red/Clear">
                  {/* Diesel Red + DEF - Gasoline  */}
                  Under Ground
                  {/* Red/Clear */}
                </option>
              </select>
              <p className="text-danger mb-0">
    {" "}
    {errors.tankType && touched.tankType && (
      <span className="input-feedback">{errors.tankType}</span>
    )}
  </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Number of Location</label>
              <input
                type="text"
                placeholder="Enter Number Of Location"
                className="form-control"
                autoComplete="off"
                id="numberOfLocation"
                name="numberOfLocation"
              value={values.numberOfLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
  {" "}
  {errors.numberOfLocation && touched.numberOfLocation && (
    <span className="input-feedback">{errors.numberOfLocation}</span>
  )}
</p>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-md-12 form-group">
            <label>Would you like to have a Quote? Please provide fueling Needs. (optional)</label>
            <textarea
              rows={6}
              cols={3}
              className="w-100"
              id="descriptionQuote"
              value={values.descriptionQuote}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <button
              className="btn btn--primary px-lg-5 px-md-3 px-2 mx-auto w-100"
              id="submit"
              onClick={formikProps.handleSubmit}
              type="submit"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ValidatedLoginForm;
