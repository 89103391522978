import React from "react";
import AdvisoryBoard from "../../AdvisoryBoard/AdvisoryBoard";
import CertifiedSupplier from "../../CertifiedSupplier/CertifiedSupplier";
import Contact from "../../Contactus/Contact";
import {
  david,
  fred,
  gregorie,
  greygory,
  owner,
  richard,
  icon12,
  icon13,
  icon15,
  leaderbanner,
  ourfueltechmission,
  polygonlarge,
  polygonsmall,
  phillip,
  lance,
  michael,
  jeff,
  geoff,
  tim,
  steve,
  certifiedbanner,
  certifiedicon,
  certifiedicon2,
  leadershipcontactbanner,
  priscopanza,
  icon20,
  lisa,
  elias,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import Services from "../../Services/Services";
import Teams from "../../Teams/Teams";
import "./LeaderShipTeam.css";

function LeaderShipTeam() {
  const type = 3;
  const contactbanner = leadershipcontactbanner;
  const certifiedblock = [
    {
      banner: certifiedbanner,
      isNewline: true,
      title: "Minority Owned ",
      titlesub: "Business Supplier",
      list1:
        "Gas It Up is one of the only Minority Business Enterprise Certified Suppliers For Mobile Fueling in the United States.",
    },
  ];
  const CertifieIcn = [
    {
      icon1: certifiedicon2,
    },
    {
      icon1: certifiedicon,
    },
  ];
  const ExclusivetitleHead = "Executive Team";
  const leadershiptitleHead = "Leadership Team";
  const TeamboardHead = "Board Members";

  const Teamboardlist = [
    {
      img: jeff,
      name: "Jeff Hoffman",
      designation: "Entreprenuer Chairman of the board",
      IsNew: true,
      desi: "Global Entrrepreunership Network",
    },
    {
      img: steve,
      name: "Steve Olyha",
      designation: "Innovation Leader",
      IsNew: true,
      desi: "Executive Coach",
    },
    {
      img: tim,
      name: "Tim Minard",
      designation: "Cheif Executive Officer",
      IsNew: true,
      desi: "Eclipse Gaming Systems",
    },
    {
      img: geoff,
      name: "Geoff Green",
      designation: "Patner at OPUS Capital Partners",
    },
    {
      img: priscopanza,
      name: "Prisco Panza",
      IsNew: true,
      designation: "President of Winsupply",
      desi: "CEO and Owner",
    },
  ];
  const Teamlist = [
    {
      img: owner,
      name: "Victor Rodriguez",
      designation: "Founder & President",
      IsLinkdin: true,
      linkdinlink: "https://www.linkedin.com/in/victor-rodriguez-4629aa21b"
    },
    // {
    //   img: david,
    //   name: "David P. Grigsby",
    //   designation: "Chief Executive Officer",
    //   IsLinkdin: true,
    //   linkdinlink: "https://www.linkedin.com/in/david-p-grigsby-b79326/"
    // },
    // {
    //   img: fred,
    //   name: "Fred Spivak",
    //   designation: "Chief Operating Officer,",
    //   IsNew: true,
    //   desi: "Chief Financial Officer",
    //   IsLinkdin: true,
    //   linkdinlink: "https://www.linkedin.com/in/fred-spivak-05968612"
    // },
    {
      img: greygory,
      name: "Gregory Carroll",
      designation: "Chief Client Officer",
    },
    {
      img: richard,
      name: "Richard Salgado",
      designation: "Chief Communication Officer",
      IsLinkdin: true,
      linkdinlink: "https://www.linkedin.com/in/richard-big-daddy-salgado-40843a20b"
    },
    {
      img: gregorie,
      name: "Gregorie Wilder",
      designation: "Chief Legal Officer , ",
      IsNew: true,
      desi: "General Counsel & Chief Tax Officer ",
      IsLinkdin: true,
      linkdinlink: "https://www.linkedin.com/in/gregorywilder"
    },
  ];
  const Teamleadershiplist = [
    // {
    //   img: phillip,
    //   name: "Phillip Warren",
    //   designation: "VP, Analytics & Data Science",
    // },
    // {
    //   img: lance,
    //   name: "Lance Nolan",
    //   designation: "VP, Operations",
    // },
    // {
    //   img: michael,
    //   name: "Michael O’keefe",
    //   designation: "VP, Sales",
    // },

    // {
    //   img: lisa,
    //   name: "Lisa DeSimone",
    //   designation: "Director of Operations",
    // },
    {
      img: elias,
      name: "Elias Barreto",
      designation: "VP, Technology",
    },
  ];

  const serviceblock = [
    {
      title: "Integrity",
      icon: icon12,
      ItemTitle: "Do the right thing, embrace challenge.",
      ItemDescription:
        "Smiling faces, quality fuel, and unbeatable customer service. We know what it takes to get you and your vehicle on the move.",
    },
    {
      title: "Trust",
      icon: icon13,
      ItemTitle: "Open-minded trust, radical transparency.",
      ItemDescription:
        "Our team is trustworthy and reliable, so you can count on us to get the job done right – every time.",
    },
    {
      title: "Safety",
      icon: icon15,
      ItemTitle: "Safety Fuels Every decision.",
      ItemDescription:
        "Safety is our number one priority. From our top-of-the-line equipment to our well-trained staff, we take every measure to ensure your safety.",
    },
    {
      title: "Disruptive",
      icon: icon20,
      ItemTitle: "Challenge tradition, innovate for tomorrow.",
      ItemDescription:
        "Get fueled at the ease of an app. Skip the boring, mundane tasks of the past and get more out of your life!",
    },
  ];
  return (
    <>
      <section className="inner-banner section-banner leadershipteam">
        <div className="leadershipteamshadow">
          <div className="container h-100">
            <div className="row align-items-center">
              <div className="col-md-6 col-12">
                <div className="home-banner-texter text-md-start text-center">
                  <h1>
                    <span
                      className="wow fadeInUp text-white"
                      data-wow-delay="0.1s"
                    >
                      Behind the
                    </span>
                    <br />
                    <span
                      className="wow fadeInUp text-orange"
                      data-wow-delay="0.2s"
                    >
                      Energy
                    </span>
                  </h1>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="text-center position-relative"
                  style={{ zIndex: "9" }}
                >
                  <figure>
                    <img
                      src={leaderbanner}
                      alt="leaderbanner"
                      className="wow zoomIn img-fluid"
                      data-wow-delay="0.1s"
                    />
                  </figure>
                  <i className="moving-shapes">
                    <img
                      src={polygonlarge}
                      alt="polygonlarge"
                      className="wow fadeInLeft"
                      data-wow-delay="0.2s"
                    />
                    <img
                      src={polygonlarge}
                      alt="polygonlarge"
                      className="wow fadeInRight"
                      data-wow-delay="0.4s"
                    />
                    <img
                      src={polygonsmall}
                      alt="polygonsmall"
                      className="wow zoomIn"
                      data-wow-delay="1s"
                    />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pb-3">
        <div className="container">
          <div className="col-md-12 col-sm-12 dashboard-listing">
            <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
              <div className="col-lg-6 col-12 ps-lg-5 ">
                <div className="heading-style text-lg-start text-center wow fadeInUp ps-lg-5 ps-0">
                  <h5 className="text-lg-start">
                    Our <span className="text-orange">Mission</span>
                  </h5>
                  <p>
                    Gas It Up, Inc. is here to ensure empty never happens,
                    bringing 24/7 mobile fueling and charging directly to your
                    customers, staff, and equipment to help keep them safe,
                    efficient, and on-the-go.
                  </p>
                  <p>
                    {" "}
                    As one of the only mobile energy suppliers in the country,
                    GIU is changing the future of fueling one tank at a time.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft right-circle dots-purple mt-3">
                <figure>
                  <img
                    src={ourfueltechmission}
                    alt="our mission"
                    className="img-fluid border-20 w-100"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="leadershipservice">
        <Services
          serviceblock={serviceblock}
          IsHead={false}
          IsSubHead={false}
          IsSubHeadcore={true}
          IsIconHead={true}
        />
      </div>
      <section className="common-section-all teammembers pt-3 pb-0 executivemembers">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="heading-style text-start wow fadeInUp mb-3">
                <h5>
                  You’re in
                  <span className="text-orange"> Good Company</span>
                </h5>
              </div>
            </div>
            <Teams Team={Teamlist} titleHead={ExclusivetitleHead} />
          </div>
        </div>
      </section>
      <section className="common-section-all teammembers pt-0 pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <Teams
                Team={Teamleadershiplist}
                titleHead={leadershiptitleHead}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all teammembers pt-0 pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <Teams Team={Teamboardlist} titleHead={TeamboardHead} />
            </div>
          </div>
        </div>
      </section>
      <AdvisoryBoard />
      <div className="pb-md-5">
        <CertifiedSupplier
          certifiedblock={certifiedblock}
          CertifieIcons={CertifieIcn}
        />
      </div>
      <section className="common-section-all business-partner py-5 mt-lg-3 leadershipbusiness">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5 className="text-capitalize">
                Trusted By <span className="text-orange"> The Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange">Gas It Up</span>{" "}
                  professional <br />
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default LeaderShipTeam;
