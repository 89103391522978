import React from "react";
import "./RetailSurface.css";
import Playstoreicons from "../../Playstoreicons/Playstoreicons";
import {
  retailbanner1,
  retailbanner2,
  retailbanner3,
  gasbanner,
  shopping,
  officeparks,
  airport,
  hotel,
  school,
  retailcontactbanner,
  gasthumbretail,
} from "../../Imagesjs/Images";
import B2c from "../../B2c/B2c";
import Newsletter from "../../Newsletter/Newsletter";
import Contact from "../../Contactus/Contact";
import FuelingFuture from "../../FuelingFuture/FuelingFuture";
import Partners from "../../Partners/Partners";
import VideoBlock from "../../VideoBlock/VideoBlock";
import { Link } from "react-router-dom";

function RetailSurface() {
  const type = 15;
  const newstype = 1;
  const contactbanner = retailcontactbanner;
  let pathname = window.location.pathname.replace(
    //eslint-disable-next-line
    /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const scenarios = [
    {
      icon: shopping,
      title: "Shopping",
    },
    {
      icon: officeparks,
      title: "Office Park",
    },
    {
      icon: airport,
      title: "Airport",
    },
    {
      icon: hotel,
      title: "Hotels",
    },
    {
      icon: school,
      title: "School",
    },
  ];
  return (
    <>
      <section className="section-banner inner-banner retailsurface">
        <div className="container">
          <div className="lines position-absolute w-100">
            <span className="circle"></span>
            <span className="circleorange"></span>
          </div>
          <div className="row align-items-start justicy-content-center retailsurfaceblock h-auto mb-0">
            <span className="circleline">
              <span className="circlecenter"></span>
            </span>
            <div className="col-lg-12 col-md-12 home-banner-texter ">
              <div className="wow fadeInUp">
                <h5 className="text-uppercase">
                  The Smart Way to <br />{" "}
                  <span className="text-orange">Get Pumped</span>{" "}
                </h5>
                <h4>Fuel, Delivered at the touch of a button.</h4>
              </div>
              <div className="storIcons">
                <Playstoreicons style={{ margin: "auto" }} />
              </div>
            </div>
          </div>
          <div className="home-banner-banner">
            <div className="col-lg-9 col-md-12 mx-auto">
              <div className="d-flex align-items-center justify-content-evenly smartgetpumped">
                <div className="text-end wow fadeInUp" data-wow-delay="0.1s">
                  <img
                    src={retailbanner2}
                    alt="retailbanner2"
                    className="img-fluid"
                  />
                </div>
                <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                  <img
                    src={retailbanner1}
                    alt="retailbanner1"
                    className="img-fluid"
                  />
                </div>
                <div className="text-start wow fadeInUp" data-wow-delay="0.3s">
                  <img
                    src={retailbanner3}
                    alt="retailbanner3"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <B2c scenarios={scenarios} Isheadtrue={true} />
      <section className="common-section-all position-relative overflow-hidden needgas pt-0">
        <div className="banner-imagess wow fadeInLeft">
          <i className="border-20">
            <img className="for-one" src={gasbanner} alt="gas banner" />
          </i>
          <img
            className="for-two wow fadeInUp"
            data-wow-delay=".5s"
            src={gasthumbretail}
            alt="Need Gas? We have an app for that."
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="offset-lg-7 col-lg-5 col-sm-12 col-xs-12 ">
              <div className="heading-style text-lg-start text-center">
                <h5 className=" text-lg-start text-center mb-4">
                  <span className="text-orange">Need Gas? </span> <br />
                  We have an app for that.{" "}
                </h5>
                <p className="fw-normal">
                  Daily drivers are able to make their days so much easier, by
                  refueling while shopping, running errands, airport / hotel
                  stays, and any other time they need to park their vehicle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-0 videoblk">
        <div className="col-md-12">
          <div className="heading-style text-end">
            <h5 className="wow fadeInUp text-end" data-wow-delay="0.2s">
              Full Is Always Better
            </h5>
            <h4 className="wow fadeInUp text-end" data-wow-delay="0.3s">
              The Gas Station Should Come to you
            </h4>
            <p className="wow fadeInUp text-end" data-wow-delay="0.4s">
              Gas It Up is an industry leading fuel management platform and
              delivery service for any fueling scenario. <br /> Fuel when you
              need it, where you need it, all at the touch of a button.
            </p>
          </div>
        </div>
        <VideoBlock path={pathname} />
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Fueling the <span className="text-orange">Community</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all">
        <FuelingFuture Istitlehaed={true} />
      </section>
      <section className="common-section-all bg-purple newsleter_signup">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInLeft">
              <div className="heading-style text-md-start text-center">
                <h5 className="text-md-start text-center">
                  Sign up for our newsletter
                </h5>
                <h4>Stay in the loop with everything you need to know. </h4>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 wow fadeInRight">
              <div
                className="heading-style text-md-start text-center"
                data-wow-delay="0.2s"
              >
                <p>
                  We care about your data in our
                  <span>
                    {" "}
                    <Link
                      to="/privacy-policy"
                      className="text-orange"
                      target="_blank"
                    >
                      privacy policy
                    </Link>
                  </span>
                </p>
                <Newsletter newstype={newstype} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5 className="text-capitalize">
                Get into contact with a{" "}
                <span className="text-orange">Gas It Up</span> professional that
                <br /> can get your{" "}
                <span className="text-orange"> business</span> set up for{" "}
                <span className="text-orange">success</span>
              </h5>
            </div>
          </div>
          <Contact
            contactbanner={contactbanner}
            type={type}
            showBusinessFields={false}
          />
        </div>
      </section>
    </>
  );
}

export default RetailSurface;
