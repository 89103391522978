import React from 'react'
import Slider from "react-slick-slider/lib/slider"
import { linkdinblue } from '../Imagesjs/Images';

import './Teams.css'
function Teams(props) {
    
const Teamssetting = {
    dots: false,
    centerMode: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
            },
            {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
            },
            {
            breakpoint: 375,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            },
        ],
    };
    
    

  return (
    <>
    <div className="col-md-12">
        <div className="heading-style text-start wow fadeInUp">
            <h4 className="text-start" style={{paddingLeft: "10px"}}>
                    {props.titleHead}
            </h4>
        </div>
    </div>
    <Slider className="image-slide mb-md-5 mb-3" {...Teamssetting}>
        {props.Team.map((teammember, i) =>                        
        <div className="clickcustomform wow animated" key={i}>
            <div className="team">
                <figure><img src={teammember.img} alt="thumb" /></figure>
                <div className="team-des">
                    <h3>{teammember.name}</h3>
                    <p>{teammember.designation}</p>
                    {teammember.IsNew === true ? <p>{teammember.desi}</p> : "" }
                    {teammember.IsLinkdin === true ? <a href={teammember?.linkdinlink} target="_blank" rel="noopener noreferrer"><i><img src={linkdinblue} alt="Linkdin" /></i></a> : "" }
                </div>
            </div>
        </div>
            )}     
    </Slider>
                
    </>
  )
}

export default Teams