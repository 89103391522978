import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import DashboardSliderslide from "../../DashboardSliderslide/DashboardSliderslide";
import Fleet from "../../Fleet/Fleet";
import {
  appstack,
  arrow,
  check,
  Deselbanner,
  deselanddefcontactbanner,
  deselefficiencydelivery,
  image3desel,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import "./DeselAndDEF.css";
function DeselAndDEF() {
  const type = 8;
  const DashboardSliderslid = [{ image: image3desel }];
  const deselDefListing = [
    "We’re available 24/7 to fit the schedule and needs of your fleet operation",
    "Our user panel reports fuel savings and direct benefit you are making on the environment ",
    "Bring optimum productivity to your operation by saving time",
  ];
  const deselDefListingefficient = [
    "Get fuel delivered to you, so you can deliver more",
    "High-quality diesel fuel and DEF brought directly to your fleet from the terminal",
    "Keep your operation running at it's fullest potential with out GIU Fuel Management Panel",
  ];
  const Contactbanner = deselanddefcontactbanner;
  return (
    <>
      <section className="inner-banner section-banner desel-def">
        <div className="container">
          <div className="row align-items-lg-center justify-content-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="home-banner-texter text-lg-start text-center">
                <h1 className="text-lg-start text-center">
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.1s"
                  >
                    Diesel on
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.2s"
                  >
                    DEMAND
                  </span>
                </h1>
                <p
                  className="text-lg-start text-center wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  The ultimate automated refueling experience for your
                  <br /> business fleet of all sizes
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mb-xl-0 mb-3">
              <div className="wow zoomIn text-center">
                <figure>
                  <img
                    src={Deselbanner}
                    alt="Deselbanner"
                    className="img-fluid w-100"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all efficient-delivery bg-grey">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5">
                  <div className="heading-style wow fadeInUp ps-lg-5">
                    <h5 className=" text-start">
                      GIU
                      <span className="text-orange"> Operation</span>
                    </h5>
                    <p>
                      The GIU Operation never uses underground storage or
                      <br /> tanks, which means fresher fuel and a safer work{" "}
                      <br />
                      environment for you, your business, and our planet!
                    </p>
                  </div>
                  <div className=" banner-actions d-flex align-items-center justify-content-start mt-2 ps-lg-5 mb-lg-0 mb-4">
                    <Link
                      to="/getafleet"
                      className="btn btn--primary clickcustomform wow fadeInUp"
                      data-wow-delay="0.8s"
                    >
                      <span>Get a Fleet Quote </span>
                      <i className="ms-3">
                        <img
                          src={arrow}
                          alt="arrow"
                          width="10px"
                          className="d-flex wow"
                        />
                      </i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft dots-purple right-circle">
                  <figure>
                    <img
                      src={appstack}
                      alt="appstack"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-start justify-content-center listing-list">
                <div className="col-lg-6 col-12 pt-lg-5">
                  <div className="heading-style wow fadeInUp">
                    <h5 className=" text-start">
                      Fuel Your <span className="text-orange">Fleet</span>
                    </h5>
                  </div>
                  <ul>
                    {deselDefListing.map((deselDefList, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {deselDefList}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center mt-lg-0 mt-3 wow fadeInRight">
                  <DashboardSliderslide
                    DashboardSliderslid={DashboardSliderslid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Fleet />
      <section className="common-section-all efficient-delivery mb-lg-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5">
                  <div className="heading-style wow fadeInUp ps-lg-5">
                    <h5 className=" text-start text-capitalize">
                      Efficiency,{" "}
                      <span className="text-orange">delivered.</span>
                    </h5>
                  </div>
                  <ul className="ps-lg-5">
                    {deselDefListingefficient.map((deselDefListing, i) => (
                      <li
                        className="wow fadeInRight"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {deselDefListing}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center my-lg-0 mb-5 mt-4 wow fadeInLeft dots-orange certified-banner">
                  <figure>
                    <img
                      src={deselefficiencydelivery}
                      alt="desel efficiency delivery"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By The <span className="text-orange">Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange">Gas It Up</span>{" "}
                  professional
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={Contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default DeselAndDEF;
