import React from "react";
// import adminpanelwebm from "../assets/images/adminpanel.webm";
// import adminpanel from "../assets/images/adminpanel.mp4";
import {
  adminpanel,
  adminpanelwebm
} from "../Imagesjs/Images";
import { smalliframe 
} from "../Imagesjs/Images";
import './TabVideo.css';

function TabVideo() {

  return (
    <>
      <div className="img-frame position-relative laptopvideoslide">
        <i>
          <img src={smalliframe} alt="iframe" className="img-fluid w-100 wow FadeInRight"  data-wow-delay="0.2s"/>
        </i>
        <div className="videobg">
       
            <video id="audio" autoPlay loop playsInline muted>
            <source src={adminpanelwebm} data-wf-ignore="true" />
            <source src={adminpanel} data-wf-ignore="true" />
          </video>
        </div>
        
      </div>
    </>
  );
}

export default TabVideo;
