import React from "react";
import CertifiedSupplier from "../../CertifiedSupplier/CertifiedSupplier";
import Contact from "../../Contactus/Contact";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  buildpumpbanner,
  check,
  def,
  fueltank,
  fueltechnicians,
  gasoline,
  renewableenergy,
  buildserve,
  lytx,
  skymark,
  largetanker,
  stateoffleetcontactbanner,
} from "../../Imagesjs/Images";
import "./StateOfArtFleet.css";

function StateOfArtFleet() {
  const type = 6;
  const contactbanner = stateoffleetcontactbanner;
  const certifiedblock = [
    {
      banner: fueltechnicians,
      isNewline: false,
      title: "High-Quality Product, Safely Brought To You By Our Professional",
      titlesub: "Fuel Technicians!",
    },
  ];

  const CertifieIcons = [
    {
      IsHeading: true,
      icon1: def,
      title: "Diesel / DEF",
    },
    {
      IsHeading: true,
      icon1: gasoline,
      title: "Gasoline",
    },
    {
      IsHeading: true,
      icon1: renewableenergy,
      title: "Renewable Energy",
    },
  ];

  const buildpump = [
    "Our 400 gallon capacity trucks are capable of fitting into parking garages and tight structures.",
    "19 ft. long (bumper to bumper) per Ford Motor Company",
    "GVW = 10,000 lbs.",
  ];
  const skymar = [
    "Approximately 1,200 gallons of capacity and will be used mainly to service corporate fleets at their locations.",
    "Measures 8' high x 8' wide and approximately 22.58 feet long (271 inches).	",
    "GVW = 19,500 lbs.",
  ];
  const largetank = [
    "The largest, holding 3,000 to 5,000 gallons, will be used to service large commercial.",
    "Measures approximately 27.75 feet long (331inches).",
    "GVW = 43,900 lbs.",
  ];
  return (
    <>
      <section className="section-banner inner-banner position-relative overflow-hidden build-pump art-fleet">
        <div className="banner-images">
          <i className="">
            <img
              className="for-one"
              src={buildpumpbanner}
              alt="build pump banner"
            />
          </i>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
              <div className="home-banner-texter text-lg-start text-center ps-lg-5">
                <h1 className="text-lg-start text-center">
                  <span className="text-white">Built to Get You </span>{" "}
                  <span className="text-orange">Pumped Up</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all tabing-list">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="">
                  Convenience,<span className="text-orange"> Delivered.</span>
                </h5>
                <h4 className="mt-2">Serving business fleets of all sizes.</h4>
              </div>
            </div>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-md-5 mb-3 pb-3 pb-md-5 wow fadeInUp"
            >
              <Tab eventKey="home" title="F-250">
                <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                  <div className="col-lg-6 col-12 text-center mb-lg-0 mb-3 wow fadeInRight">
                    <figure>
                      <img
                        src={fueltank}
                        alt="fuel tank"
                        className="img-fluid border-20"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      {buildpump.map((beyondpumpList, i) => (
                        <li
                          className="wow fadeInLeft"
                          data-wow-delay={`0.` + i + `s`}
                          key={i}
                        >
                          <i className="me-lg-3 me-2">
                            <img src={check} alt="check" />
                          </i>
                          {beyondpumpList}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="1200 & Hino 195">
                <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                  <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5">
                    <figure>
                      <img
                        src={skymark}
                        alt="skymark"
                        className="img-fluid border-20"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      {skymar.map((skymarkList, i) => (
                        <li key={i}>
                          <i className="me-3">
                            <img src={check} alt="check" />
                          </i>
                          {skymarkList}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="contact" title="Large Size Tanker">
                <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                  <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5">
                    <figure>
                      <img
                        src={largetanker}
                        alt="large tanker"
                        className="img-fluid border-20"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-6 col-12">
                    <ul>
                      {largetank.map((largetankList, i) => (
                        <li key={i}>
                          <i className="me-3">
                            <img src={check} alt="check" />
                          </i>
                          {largetankList}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
      <section className="common-section-all py-0 my-3 buildserve">
        <div className="build-serve">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 px-0">
                <figure>
                  <img
                    src={buildserve}
                    alt="build serve"
                    className="w-100 wow fadeInLeft"
                    height="550px"
                    style={{ objectFit: "cover", objectPosition: "top" }}
                  />
                </figure>
              </div>
              <div className="col-lg-6 col-md-12 my-5 buidservetext">
                <div className="heading-style text-start wow fadeInRight">
                  <h5 className="text-start">Built to Serve</h5>
                  <p>
                    Inspected and approved by local fire marshals in every
                    active GIU market. Equipped with safety functions and
                    equipment such as: vapor recovery units, emergency kill
                    switches, overflow protection, grounding cables, and more to
                    ensure the safety of our users and environment.
                  </p>
                </div>
                <p
                  className="bg-white d-inline-block py-2 px-3  wow zoomIn"
                  data-wow-delay="0.5s"
                >
                  <img src={lytx} alt="lytx" className="img-fluid" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="highQuality-fuel mb-lg-5">
        <CertifiedSupplier
          certifiedblock={certifiedblock}
          CertifieIcons={CertifieIcons}
        />
      </div>
      <section className="common-section-all pt-md-4 pt-0 leadershipbusiness">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange">Gas It Up</span>{" "}
                  professional
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default StateOfArtFleet;
